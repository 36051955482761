import CheckIcon from "../images/icon/check.svg";
import Utm from "../components/_global/utm";

// images
import TopBackground from "../images/organic-textile_gots.jpeg";

export const pageTexts = (t) => {
  return {
    topInfo: {
      title: {
        text: t("sustainability.gots-x-foursource.topTitle"),
        colorClass: "colorLight",
      },
      subtitle: {
        text: t("sustainability.gots-x-foursource.topSubtitle"),
        colorClass: "colorLight",
      },
      bottomDiagonal: true,
      opacity: true,
      image: {
        src: TopBackground,
        alt: t("sustainability.gots-x-foursource.imgAlt"),
        smallHeight: true,
        bottomPosition: true
      },
    },
    firstText: [
      {
        text: t("sustainability.gots-x-foursource.globalizationIndustry"),
        colorClass: "colorDark",
        textType: "paragraph",
      },
    ],
    whatIs: {
      title: {
        text: t("sustainability.gots-x-foursource.whatIs"),
        colorClass: "colorDark",
      },
      text: {
        text: t("sustainability.gots-x-foursource.gotsStandard"),
        colorClass: "colorDark",
        textType: "paragraph",
      },
      separator: true,
    },
    whatIsText: [
      {
        text: t("sustainability.gots-x-foursource.standardRequirement"),
        colorClass: "colorDark",
        textType: "paragraph",
      },
    ],
    standardsCols: [
      {
        subtitle: {
          text: t("sustainability.gots-x-foursource.grade1"),
          colorClass: "colorDark",
        },
        icon: { fontAwesome: false, src: CheckIcon, alt: t("homepage.checkAlt")},
        separator: false,
        item: {
          text: [t("sustainability.gots-x-foursource.95organic")],
          colorClass: "colorDark",
        },
        noPadding: true,
        customPBText: true,
      },
      {
        subtitle: {
          text: t("sustainability.gots-x-foursource.grade2"),
          colorClass: "colorDark",
        },
        icon: { fontAwesome: false, src: CheckIcon, alt: t("homepage.checkAlt")},
        separator: false,
        item: {
          text: [t("sustainability.gots-x-foursource.70organic")],
          colorClass: "colorDark",
        },
        noPadding: true,
        customPBText: true,
      },
    ],
    standards: {
      title: {
        text: t("sustainability.gots-x-foursource.topTitle"),
        colorClass: "colorDark",
      },
      separator: true,
    },
    GOTSButton: {
      text: t("sustainability.gots-x-foursource.learnMore"),
      url: "https://www.global-standard.org/",
      type: "mainColor",
    },
    resultsButton: {
      text: t("sustainability.gots-x-foursource.seeMore"),
      url:process.env.GATSBY_CHINA === "true" ? `${process.env.APP_CHINA_URL}/signup/` : Utm(`${process.env.APP_URL}/signup/`),
      type: "mainColor",
    },
  };
};
